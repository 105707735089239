.process-complete {
  display: grid;
  grid-template-rows: 59px [image-s] 95px [image-e] 15px [title-s] auto [title-e] 50px [message-s] auto [message-e] 30px;
  justify-items: center;

  &__image {
    grid-row: image-s/image-e;
    height: 95px;
    width: 100px;
  }
  &__title {
    grid-row: title-s/title-e;
    @include green-heading;
  }
  &__message {
    grid-row: message-s/message-e;
    @include p-text;
  }
}
