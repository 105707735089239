@import "../mixins";

.track-selected {
  display: flex;
  flex-direction: row;

  &__track-information,
  &__track-selected-options {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__track-information {
    margin: 0 30px 0 0;
  }

  @include mobile {
    flex-direction: column;

    &__track-information {
      margin: 0 0 30px 0;
    }
  }
}

.title-with-logo {
  display: grid;
  grid-template-columns: [logo-s] 55px [logo-e subtitle-s title-s] 1fr [subtitle-e title-e];
  grid-template-rows: [logo-s title-s] 1fr [title-e subtitle-s] 0fr [subtitle-e logo-e];
  grid-column-gap: 20px;

  &__logo {
    height: 55px;
    width: 55px;
    object-fit: contain;

    grid-column: logo-s/logo-e;
    grid-row: logo-s/logo-e;
  }

  &__title {
    color: $color-CB;
    font-weight: bold;
    font-size: 20px;

    grid-column: title-s/title-e;
    grid-row: title-s/title-e;
  }
  &__subtitle {
    color: $color-33;
    font-size: 14px;
    font-family: $font-nun-sans;

    grid-column: subtitle-s/subtitle-e;
    grid-row: subtitle-s/subtitle-e;
  }
}

.track-details {
  font-family: $font-nun-sans;
  font-size: 14px;

  p {
    margin: 10px 0;
  }
}
