.form-with-buttons {
  display: grid;
  grid-template-rows: [card-s] 1fr [card-e buttons-s] 140px [buttons-e];
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;

  & > * {
    grid-column: 1/-1;
  }
  &__card {
    justify-content: center;
    width: 50%;
    grid-row: card-s/card-e;
  }
  &__buttons {
    width: 50%;
    grid-row: buttons-s/buttons-e;

    margin: 40px 0px;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 260px;

    justify-content: space-evenly;
    justify-items: center;
  }
}
