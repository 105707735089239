@import "../../../style/variables";
@import "../../../style/mixins";

.form-select-list {
  display: grid;
  grid-template-rows: [form-error-s] minmax(25px, auto) [form-error-e select-list-s] 1fr [select-list-e];
  grid-template-columns: [s] 1.4fr [m] 1fr [hover-icon-s] auto [hover-icon-e e];

  &__title {
    grid-row: form-error-s/form-error-e;
    grid-column: s/e;
    align-self: center;
    margin-bottom: 8px;
    line-height: 18px;
    padding-right: 30px;
    @include survey-title-text();
  }
  &__error {
    grid-row: form-error-s/form-error-e;
    grid-column: m/hover-icon-s;
    justify-self: end;
    align-self: center;
    margin-bottom: 8px;
    line-height: 18px;
    white-space: nowrap;
  }
  &__hover-icon {
    color: $color-80;
    font-size: 18px;
    padding-left: 5px;
    margin-bottom: 8px;
    line-height: 18px;
    align-self: center;
    justify-self: end;
    grid-row: form-error-s/form-error-e;
    grid-column: hover-icon-s/hover-icon-e;
  }
  &__select-list {
    grid-row: select-list-s/select-list-e;
    grid-column: s/e;

    * {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    ul {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;

      list-style: none;
    }

    li {
      height: 40px;
      width: 295px;
      border: 1px solid #999999 !important;
      padding-left: 1em;

      @include mobile {
        width: 90%;
      }

      &:hover {
        background-color: #e6e6e6;
      }

      label {
        height: 100%;
        width: 100%;
        @include survey-option-text();
        display: grid;
        align-items: center;
        position: relative;

        cursor: pointer;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        span {
          display: inline-block;
        }

        :checked + span:before {
          content: " ";
          height: 15px;
          width: 15px;
          position: relative;
          display: inline-block;
          top: 2px;
          border-left: 15px solid $color-CB !important;
        }

        span:before {
          content: " ";
          height: 15px;
          width: 15px;
          position: relative;
          display: inline-block;
          top: 2px;
          border-left: 15px solid #eee;
        }
      }
    }

    li:last-child {
      justify-self: end;
    }
  }
}
