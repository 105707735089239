.numbered-list {
  margin-top: 20px;
  &__item {
    &__title {
      @include green-heading($font-size: 18px, $text-align: left);
    }
    &__description {
      margin-top: 14px;
      margin-bottom: 50px;
      @include p-text($text-align: left);
    }
    &__content-component {
      & > * {
        margin: 0 50px;
      }
      .green-titled-list {
        grid-template-columns: [s] 100% [e];
      }
    }
    margin-bottom: 50px;
  }
}
