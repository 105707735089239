@import "../../mixins";

.enrollment-agreement-doc {
  width: 900px;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);

  @include mobile {
    width: calc(100vw - 20px);
    overflow-x: scroll;
  }
}
