.progress-bar {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, 1fr);

  & > * {
    grid-row: 1/-1;
  }
}

.progress-bar-page {
  @include grid-center($gap: 16px);

  &__box {
    width: 100%;
    @include grid-center();
    &__line {
      height: 2px;
      background: $color-80;
      width: 100%;
      align-self: center;

      transform: translateY(14px) translateX(50%);
    }

    &__box {
      align-self: start;

      height: 24px;
      width: 24px;
      border: 1px solid $color-99;
      transform: rotate(45deg);
      background-color: $color-FF;

      @include grid-center();

      .number {
        transform: rotate(-45deg);
        height: 19px;
        width: 9px;
        color: $color-CB;
        font-family: $font-nun-sans;
        font-size: 14px;
        text-align: center;
      }
      .hide-number {
        display: none;
      }
    }
  }

  &__title {
    @include grid-center();
    height: 36px;

    color: $color-99;
    font-family: $font-nun-sans;
    font-size: 16px;
    text-align: center;
    width: 81px;
  }
}

.green-text {
  color: $color-CB;
}
.green-border {
  border: 2px solid $color-CB;
}
.green-border-bottom {
  border-bottom: 4px solid $color-CB;
}
.tab-text {
  font-family: $font-rob-mono;
  font-size: 18px;
  margin-right: 50px;
  padding-bottom: 10px;
}
.hide {
  display: none;
}
