@import "../mixins";

.financial-guidance {
  margin-bottom: 0;
  width: 700px !important;

  display: grid;
  justify-items: center;

  grid-template-columns: [s] 1fr [e];

  @include mobile {
    width: 100% !important;
  }

  &__social-media {
    margin-bottom: 40px;
  }

  & > * {
    grid-column: s/e;
  }
  &__pledge {
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
    .titled-container {
      &__title {
        justify-content: center;
        color: #000;
        padding-left: 0;
        background-color: $color-FF;
        margin-top: 20px;
      }
      &__content {
        padding: 46px 66px;
        box-shadow: none;
        @include p-text();

        @include mobile {
          padding: 10px;
        }
      }
    }
  }
  &__loans {
    margin-bottom: 200px;

    .titled-container__content {
      padding: 46px 24px;
      @include p-text();
      @include mobile {
        padding: 10px;
      }
    }
    .loan-form__message {
      padding: 0px 15px 25px 15px;
      @include mobile {
        padding: 10px;
      }
    }
  }
}
