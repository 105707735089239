.green-titled-info {
  display: grid;
  justify-items: center;

  &__title {
    color: $color-CB;
    font-family: $font-nun-sans;
    font-size: 14px;
    text-align: center;
  }
  &__duration {
    color: $color-80;
    font-family: $font-nun-sans;
    font-size: 16px;
    line-height: 22px;
  }
}
