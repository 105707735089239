.layout {
  position: relative;
  width: 100vw;

  display: grid;
  grid-template-columns: [s] 140px [content-s] 1fr [content-e] 140px [e];
  grid-template-rows: [branded-header-s] 50px [branded-header-e progress-bar-s] 171px [progress-bar-e content-s] 1fr [content-e];
  justify-items: center;

  @media only screen and (max-width: 750px) {
    grid-template-columns: [s] 10px [content-s] 1fr [content-e] 10px [e] !important;
  }
  @media only screen and (max-width: 350px) {
    grid-template-columns: [s] 0px [content-s] 1fr [content-e] 0px [e] !important;
  }

  &__branded-header {
    width: 100%;
    grid-row: branded-header-s/branded-header-e;
    grid-column: s/e;
  }

  &__progress-bar {
    width: 660px;
    grid-row: progress-bar-s/progress-bar-e;
    grid-column: s/e;
    justify-self: center;
    align-self: center;
  }

  &__content {
    grid-row: content-s/content-e;
    grid-column: content-s/content-e;
    display: grid;

    grid-row-gap: 42px;
  }
}

@media only screen and (max-width: 600px) {
  .layout {
    width: 20vw;
  }
}
