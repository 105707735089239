@import "../mixins";

.loan-form-options-container {
  & > * {
    margin-bottom: 70px;
  }
  &:last-child {
    margin-bottom: -146px;
    @include mobile {
      margin-bottom: -100px;
    }
  }
  &__title {
    font-family: $font-rob-mono;
    font-size: 18px;
    color: $color-33;
    text-align: left;

    padding-left: 28px;
    padding-bottom: 5px;

    border-bottom: 1px solid $color-33;
    margin-bottom: 25px;

    @include mobile {
      padding-left: 0;
      text-align: center;
    }
  }
  &__description {
    text-align: left;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
  }
  &__options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-row-gap: 20px;
    justify-content: space-between;

    @include mobile {
      grid-template-columns: 1fr;
    }

    & > :first-child {
      justify-self: start;
    }
    & > :nth-child(even) {
      justify-self: end;
    }
  }
}
