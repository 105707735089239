@import "./mixins";

.green-titled-list {
  display: grid;
  grid-template-columns: [s] 460px [e];
  justify-items: left;
  grid-row-gap: 14px;

  @include mobile {
    grid-template-columns: [s] auto [e];
    justify-items: center;
  }

  &__title {
    grid-column: s/e;
    color: $color-CB;
    text-align: left;
    margin: 0;

    font-family: $font-rob-mono;
    font-size: 18px;
    margin-left: -20px;

    @include mobile {
      margin-left: 0;
    }
  }
  &__items {
    grid-column: s/e;
    display: grid;
    grid-row-gap: 20px;
    @include p-text();
    text-align: left;
    li {
      @include p-text();
      text-align: left;
    }
  }
}
