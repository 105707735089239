@import "./mixins";

.titled-container {
  display: grid;
  grid-template-columns: [s] 700px [e];
  grid-template-rows: [header-s] 50px [header-e content-s] 1fr [content-e];

  @include mobile {
    width: 100%;
    grid-template-columns: [s] 1fr [e];
  }

  &__title,
  &__content {
    grid-column: s/e;
    display: grid;
  }
  &__title {
    z-index: 10;
    grid-row: header-s/header-e;
    display: grid;
    align-items: center;
    padding-left: 52px;

    height: 55px;
    background-color: $color-1A;

    color: $color-FF;
    font-family: $font-rob-mono;
    font-size: 20px;
    line-height: 26px;

    font-weight: normal;

    @include mobile {
      width: 100%;
      min-height: 45px;
      padding: 0;
      justify-items: center;
      text-align: center;
      font-size: 18px;
    }
  }
  &__content {
    grid-row: content-s/content-e;
    display: grid;

    background-color: $color-FF;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);

    padding: 40px 21px;

    @include mobile {
      padding: 20px 11px;
    }
  }
}
