// GRAYS

$color-1A: #1a1a1a;
$color-80: #808080;
$color-99: #999999;
$color-FF: #fff;
$color-33: #333;
$color-CC: #ccc;
$color-4D: #4d4d4d;
$color-66: #666666;

// GREENS

$color-CB: #00cb79;

// FONTS

$font-rob-mono: "Roboto Mono";
$font-nun-sans: "Nunito Sans";
