.cohort-details {
  margin-top: 20px;
  margin-bottom: 40px;

  height: 100px;
  width: 100%;

  display: grid;
  grid-template-rows: [logo-s title-s] 0.5fr [title-e subtitle-s] 0.5fr [subtitle-e start-date-s] 1fr [start-date-e logo-e];
  grid-template-columns: [logo-s] 125px [logo-e] 40px [s] 1fr [e];

  justify-items: start;

  &__logo {
    grid-row: logo-s/logo-e;
    grid-column: logo-s/logo-e;
    align-self: center;
    justify-self: center;

    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    grid-row: title-s/title-e;
    grid-column: logo-e;
    align-self: end;
    @include green-heading($font-size: 24px);
    text-align: left;
  }

  &__subtitle {
    grid-row: subtitle-s/subtitle-e;
    grid-column: logo-e;
    align-self: start;

    font-size: 14px;
    color: $color-4D;
  }

  &__start-date {
    grid-row: start-date-s/start-date-e;
    grid-column: s/e;
    align-self: end;

    font-size: 16px;
    color: $color-4D;

    font-family: $font-nun-sans;
  }
}
