.orientation-reminder {
  display: flex;

  flex-flow: column;

  margin-bottom: 50px;

  & > * {
    margin: 10px 0;
  }
  &__title {
    color: $color-CB;
    text-align: left;
    margin: 0;

    font-family: $font-rob-mono;
    font-size: 18px;
  }
  p {
    @include p-text($text-align: left);
  }
  &__date {
    background-color: #f2f1f2;
    height: 100px;
    width: 60%;
    margin: 24px auto;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;

    justify-items: center;

    & > * {
      grid-column: 1/-1;
    }

    &__date {
      align-self: end;
      grid-row: 1/2;
      @include p-text($font-family: $font-rob-mono);
    }
    &__time {
      align-self: start;
      grid-row: 2/3;
      @include p-text($font-family: $font-rob-mono, $font-size: 12px);
    }
  }
}
