.card-info {
  max-width: 550px;
  width: 100%;

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  & > * {
    grid-column: 1/-1;
    text-align: center;
  }
  h1 {
    color: $color-33;
    grid-row: 1/2;
    font-size: 18px;
  }
  p {
    color: $color-99;
    grid-row: 2/3;
    font-family: $font-nun-sans;
    font-size: 15px;
  }
}
