@import "../mixins";

@mixin card {
  padding: 20px;
  color: black;
  background: white;
  width: 400px;
  box-shadow: 5px 2px 20px 4px rgba(40, 40, 40, 0.12);
}

.popup {
  transition: all 0.6s ease;

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;

  &.hide {
    opacity: 0;
    transition: all 0.6s ease;
  }

  &__overlay {
    opacity: 0.5;
    background-color: #000;

    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
  }

  &__card {
    @include card;

    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 100;

    display: grid;
    grid-template-rows: [message-s] auto [message-e button-s] auto [button-e];
    grid-template-columns: 1fr;

    &__message {
      @include p-text();
      grid-row: message-s/message-e;
      grid-column: 1/-1;

      margin: 0 0 20px 0;
    }

    &__buttons {
      grid-row: button-s/button-e;
      grid-column: 1/-1;
      justify-self: center;

      display: flex;
    }

    &__button {
      margin: 0 15px;

      button.form-button__button {
        height: 30px;
        width: 120px;

        font-size: 14px;
      }
    }
  }
}
