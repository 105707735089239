@import "../../mixins.scss";

.applicant-information-fields-form {
  display: grid;
  grid-template-rows: 1fr 200px;
  width: 750px;

  @include mobile {
    width: 100%;
  }

  &__card {
    grid-row: 1/2;
  }

  &__buttons {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: [continue-s] 1fr [continue-e];
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    & > * {
      grid-row: 1/-1;
    }
    .applicant-information-fields-form {
      &__continue {
        grid-column: continue-s/continue-e;
      }
    }

    @include mobile {
      grid-template-rows: 200px;
    }
  }

  &__separator {
    grid-column: s/e;
    height: 20px;
    width: 100%;

    @include mobile {
      height: 0;
    }
  }
}

.applicant-information-fields-form {
  &__title {
    grid-row: title-s/title-e;
    grid-column: s/e;

    z-index: 10;

    display: grid;
    align-items: center;
    padding-left: 52px;

    height: 55px;
    background-color: $color-1A;

    color: $color-FF;
    font-family: $font-rob-mono;
    font-size: 20px;
    line-height: 26px;

    font-weight: normal;
  }

  &__first,
  &__last {
    input {
      background-color: #dddddd;
    }
  }

  &__first,
  &__city,
  &__gender,
  &__education {
    grid-column: s/m;
  }

  &__phone {
    grid-column: s/me;
  }

  &__phone-number-type {
    grid-column: me/e;
  }

  &__citizenship {
    grid-column: se/m;
  }

  &__us-citizen {
    grid-column: s/se;
  }

  &__emergency-contact {
    grid-column: m/e;
  }

  &__address {
    grid-column: s/e;
  }

  &__last,
  &__military,
  &__previous {
    grid-column: m/e;
  }

  &__month {
    grid-column: s/sm;
  }
  &__day {
    grid-column: sm/me;
  }
  &__year {
    grid-column: me/e;
  }

  &__state {
    grid-column: m/me;
  }

  &__zipcode {
    grid-column: me/e;
  }

  &__site {
    grid-column: m/e;
  }

  &__high-school-of-graduation {
    grid-column: s/m;
  }

  &__colleges-attended {
    grid-column: m/e;
  }

  &__diploma-or-ged,
  &__high-school-diploma {
    grid-column: s/m;
  }

  &__colleges-terms-attended {
    grid-column: m/e;
  }

  &__degree-earned {
    grid-column: s/m;
  }

  &__va-file-number-and-chapter {
    grid-column: s/m;
  }

  &__social-security-number {
    grid-column: m/e;
  }

  &__reference-title {
    grid-column: s/e;

    margin: 15px 0 8px 0;

    color: #000;
    font-family: $font-nun-sans;
    font-size: 14px;

    font-weight: normal;
  }

  &__reference-name {
    grid-column: s/m;
  }

  &__reference-phone {
    grid-column: m/e;
  }

  @include mobile {
    &__title {
      font-size: 18px;
    }

    &__city {
      grid-column: s/e;
    }
    &__state {
      grid-column: s/m;
    }
    &__zipcode {
      grid-column: m/e;
    }
    &__month {
      grid-column: s/m;
    }
    &__day {
      grid-column: m/me;
    }
    &__year {
      grid-column: me/e;
    }
    &__gender {
      grid-column: s/m;
    }
    &__military {
      grid-column: s/e;
    }
    &__education {
      grid-column: s/e;
    }
    &__previous {
      grid-column: s/e;
    }
    &__phone {
      grid-column: s/m;
    }

    &__phone-number-type {
      grid-column: m/e;
    }

    &__citizenship {
      grid-column: se/e;
    }

    &__us-citizen {
      grid-column: s/se;
    }

    &__emergency-contact {
      grid-column: s/e;
    }

    &__high-school-of-graduation {
      grid-column: s/e;
    }

    &__colleges-attended {
      grid-column: s/e;
    }

    &__diploma-or-ged,
    &__high-school-diploma {
      grid-column: s/e;
    }

    &__colleges-terms-attended {
      grid-column: s/e;
    }

    &__degree-earned {
      grid-column: s/e;
    }

    &__va-file-number-and-chapter {
      grid-column: s/e;
    }

    &__social-security-number {
      grid-column: s/e;
    }
  }
}
