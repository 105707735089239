.doc {
  font-size: 14px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 5rem;
}

.doc {
  display: grid;
  grid-template-columns: [s] 1fr [e];

  & > * {
    text-align: center;
    grid-column: s/e;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 5rem;
    &__left {
      margin-bottom: 0rem;
      text-align: left;
    }
  }
  &__address {
    justify-self: center;
    margin-bottom: 6rem;
  }
}

.mt {
  &_5 {
    margin-top: 5px;
  }
  &_10 {
    margin-top: 10px;
  }
  &_20 {
    margin-top: 20px;
  }
  &_30 {
    margin-top: 30px;
  }
  &_40 {
    margin-top: 40px;
  }
  &_50 {
    margin-top: 50px;
  }
}

.mb {
  &_50 {
    margin-bottom: 50px;
  }
  &_5 {
    margin-bottom: 5px;
  }
  &_10 {
    margin-bottom: 10px;
  }
  &_20 {
    margin-bottom: 20px;
  }
  &_30 {
    margin-bottom: 30px;
  }
  &_40 {
    margin-bottom: 40px;
  }
  &_80 {
    margin-bottom: 80px;
  }
  &_100 {
    margin-bottom: 100px;
  }
  &_150 {
    margin-bottom: 15rem;
  }
}
.underline {
  border-bottom: 1px solid black;
}

.mr {
  &_10 {
    margin-right: 10px;
  }
  &_5 {
    margin-right: 5px;
  }
}

.ml {
  &_50 {
    margin-left: 50px;
  }
  &_5 {
    margin-left: 5px;
  }
  &_10 {
    margin-left: 10px;
  }
  &_20 {
    margin-left: 20px;
  }
  &_30 {
    margin-left: 30px;
  }
  &_35 {
    margin-left: 35px;
  }
  &_40 {
    margin-left: 40px;
  }
}
.eng-model {
  margin: 5rem 0;
  &__choosing {
    margin: 3rem 0;
  }
}

.forty {
  height: 40px;
}

.eighty {
  height: 80px;
}

.eng-model-grid {
  display: grid;
  grid-template-rows: 600px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &__titles {
    & > * {
      border: 1px solid black;
      display: grid;
      padding-left: 6px;
      align-items: center;
    }
    grid-row: 1/2;
    text-align: left;

    display: grid;
    grid-template-rows: repeat(10, 40px) 80px 40px;
    grid-template-columns: 1fr;
  }

  &__option {
    & > * {
      border: 1px solid black;
      display: grid;
      justify-items: center;
      align-items: center;
    }
    display: grid;
    grid-template-rows: repeat(10, 40px) 80px 40px;
    grid-template-columns: 1fr;

    & > * {
      grid-column: 1/-1;
    }
  }
}

.no-border {
  border: none;
}
