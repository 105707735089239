@import "../mixins";

.course-selection {
  @include card;

  display: grid;
  grid-template-columns: [s] 1fr [m] 1fr [e];
  grid-template-rows: [change-s] 57px [change-e image-s] 96px [image-e title-s] 1fr [title-e format-s] 1fr [format-e partner-s] 1fr [partner-e course-duration-s start-date-s] 2fr [course-duration-e start-date-e];

  @include mobile {
    grid-template-rows: [change-s] 47px [change-e image-s] 76px [image-e title-s] 1fr [title-e format-s] 1fr [format-e partner-s] 0fr [partner-e course-duration-s start-date-s] 1fr [course-duration-e start-date-e];
  }

  justify-items: center;
  align-items: center;

  font-weight: normal;

  & > * {
    grid-column: s/e;
  }
  &__change {
    grid-row: change-s/change-e;
    justify-self: end;
    align-self: start;
  }
  &__img {
    grid-row: image-s/image-e;
    margin-bottom: 35px;
    @include mobile {
      margin-bottom: 10px;
    }
  }
  &__title {
    grid-row: title-s/title-e;
  }
  &__format {
    grid-row: format-s/format-e;
  }
  &__partner {
    grid-row: partner-s/partner-e;
  }
  &__course-duration {
    grid-row: course-duration-s/course-duration-e;
    grid-column: s/m;
  }
  &__start-date {
    grid-row: start-date-s/start-date-e;
    grid-column: m/e;
  }
  &__course-duration,
  &__start-date {
    @include eighty-text();
    .green-titled-info {
      &__title {
        margin-bottom: 7px;
      }
    }
  }
}

.course-selection {
  &__change {
    cursor: pointer;
  }

  &__img {
    width: 96px;
    height: 96px;

    @include mobile {
      width: 76px;
      height: 76px;
    }
  }
  &__title {
    color: $color-CB;
    font-family: $font-rob-mono;
    font-size: 34px;
    text-align: center;
    @include mobile {
      font-size: 24px;
    }
  }
  &__change {
    display: flex;
    &__title {
      color: $color-99;
      font-family: $font-nun-sans;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }
    &__button {
      margin-left: 10px;
      text-decoration-line: underline;

      color: #45b0e5;
      font-family: $font-nun-sans;
      font-size: 14px;
      text-align: center;
    }
  }
  &__format {
    color: $color-1A;
    font-family: $font-nun-sans;
    font-size: 18px;
    text-align: center;
  }
  &__partner {
    color: $color-99;
    font-family: $font-nun-sans;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }

  &__course-duration {
  }
  &__start-date {
  }
}
