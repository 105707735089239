.account-created {
  @include card;
  display: grid;
  grid-template-rows: 59px [image-s] 95px [image-e] 15px [title-s] auto [title-e] 50px [message-s] auto [message-e] 50px [message2-s] auto [message2-e] 50px [message3-s] auto [message3-e] 50px [button-s] auto [button-e] 30px;

  justify-items: center;
  align-items: center;
  font-family: $font-nun-sans;
  font-size: 18px;
  font-weight: normal;

  &__image {
    grid-row: image-s/image-e;
    height: 95px;
    width: 100px;
  }
  &__title {
    grid-row: title-s/title-e;
    @include green-heading;
  }
  &__message {
    grid-row: message-s/message-e;
    @include p-text;
  }

  &__message_with_image {
    grid-row: message2-s/message2-e;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    @include p-text;

    @include mobile {
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr;
    }
  }

  &__message2 {
    align-items: center;
    display: flex;
  }

  &__message3 {
    grid-row: message3-s/message3-e;
    @include p-text;
  }

  &__image_timer {
    border-radius: 50%;
    width: 90%;
    border: 1px solid $color-CC;

    @include mobile {
      width: 60%;
    }
  }

  &__button {
    grid-row: button-s/button-e;
  }
}
