.student-orientation-form {
  display: grid;
  justify-items: center;
  align-items: start;
  grid-row-gap: 20px;

  grid-template-rows: [body-s] auto [body-e buttons-s] 200px [buttons-e];
  grid-template-columns: [s] 1fr [e];

  & > * {
    grid-column: s/e;
  }

  &__body {
    grid-row: body-s/body-e;

    .titled-container__content {
      grid-row-gap: 40px;

      ul {
        justify-self: center;
      }

      .green-titled-list {
        justify-content: center;

        &__title,
        &__items {
          text-align: center;
          margin: 0 auto;
        }
      }
    }
  }

  &__continue {
    align-self: center;
    grid-column: s/e;
    justify-self: center;
    grid-row: buttons-s/buttons-e;
    margin-top: -42px;
  }
}

.calendly {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  max-width: calc(100vw - 30px);
  height: 720px;
  padding: 0;
}
