@import "../mixins";

.commitment-assessment-form {
  $marginBottom: 10px;
  font-family: $font-nun-sans;
  font-size: 15px;
  color: $color-1A;
  width: 700px;

  @include mobile {
    width: 100%;
  }

  hr {
    color: $color-CC;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .commitment-assessment-section:not(:last-child) {
    margin-bottom: 35px;
  }

  .commitment-assessment-description {
    font-size: 16px;
    margin-bottom: $marginBottom;
  }

  .commitment-assessment-paragraph-heading {
    color: $color-CB;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    margin-left: 5px;
    margin-bottom: $marginBottom;
  }

  .commitment-assessment-paragraph {
    font-weight: 300;
    margin-bottom: $marginBottom;
  }

  .commitment-slider-wrapper {
    margin: 30px 55px 20px 55px;

    @include mobile {
      margin: 0;
    }
  }

  .form-select-list {
    margin-top: -15px;
  }

  .commitment-assessment-continue {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
