@import "../mixins";

.page-layout {
  position: relative;
  width: 100vw;

  display: grid;
  grid-template-columns: [s] 10px [content-s] 1fr [content-e] 10px [e];
  grid-template-rows: [page-header-s] 50px [page-header-e page-bar-s] 171px [page-bar-e content-s] 1fr [content-e];
  justify-items: center;

  @include mobile {
    grid-template-columns: [s] 10px [content-s] 1fr [content-e] 10px [e];
    grid-template-rows: [page-header-s] 40px [page-header-e page-bar-s] 125px [page-bar-e content-s] 1fr [content-e];
  }

  &__content {
    grid-row: content-s/content-e;
    grid-column: content-s/content-e;

    @include mobile {
      width: 100%;
    }
  }
  &__page-header {
    width: 100%;
    grid-row: page-header-s/page-header-e;
    grid-column: s/e;
  }
  &__page-bar {
    grid-row: page-bar-s/page-bar-e;
    grid-column: s/e;
    justify-self: center;
    align-self: center;

    @include mobile {
      justify-self: flex-start;
    }
  }
}

.page-bar {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, 1fr);

  & > * {
    grid-row: 1/-1;
  }

  @include mobile {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100vw;
  }
}

.page-bar-diamond {
  @include grid-center($gap: 16px);
  grid-template-rows: [number-s diamond-s line-s] 1fr [number-e diamond-e line-e title-s] auto [title-e];
  grid-template-columns: [line-s] 1fr [line-e];
  grid-gap: 0;

  & > * {
    grid-column: 1/-1;
  }

  &__line {
    width: calc(100% - 17px);
    transform: translateX(-50%);
    align-self: center;

    border-top: 2px solid $color-80;

    grid-row: line-s/line-e;
    grid-column: line-s/line-e;

    &-dashed {
      border-top: 2px dashed #45b0e5;
    }
  }

  &__number {
    grid-row: number-s/number-e;
    z-index: 11;
  }

  &__diamond {
    grid-row: diamond-s/diamond-e;
    height: 24px;
    width: 24px;
    border: 1px solid $color-99;
    transform: rotate(45deg);
    background-color: $color-FF;
    z-index: 10;
  }

  &__title {
    height: 36px;
    color: $color-99;
    font-family: $font-nun-sans;
    font-size: 16px;
    text-align: center;
    width: 81px;
    grid-row: title-s/title-e;

    @include mobile {
      font-size: 14px;
    }
  }

  &__step-title {
    position: relative;
    left: -64px;
    grid-row: line-s/line-e;

    width: 72px;
    padding: 0 30px;
    height: 50px;

    color: #45b0e5;
    font-family: $font-nun-sans;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
  }
}

.page-bar-diamond-done {
  .page-bar-diamond {
    &__diamond {
      border-color: $color-CB;
    }
    &__number,
    &__title {
      color: $color-CB;
    }
    &__line {
      border-top: 2px dashed $color-CB;
    }
  }
}
