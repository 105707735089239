@import "../mixins";

.document-collection {
  display: grid;
  grid-row-gap: 42px;
}

.document-collection-form {
  display: grid;
  grid-template-rows: 1fr 200px;
  width: 750px;

  @include mobile {
    width: 100%;
  }

  &__card {
    grid-row: 1/2;
  }

  .form-input,
  .form-file {
    margin-bottom: 14px;
  }

  &__buttons {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 0.25fr [continue-s] 1fr [continue-e] 0.25fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    & > * {
      grid-row: 1/-1;
    }
    .document-collection-form {
      &__continue {
        grid-column: continue-s/continue-e;
      }
    }
  }
}
