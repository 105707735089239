.scheduled-student-orientation {
  &__date {
    color: $color-4D;
    font-family: $font-rob-mono;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    margin-bottom: 4px;
  }
  &__time {
    color: $color-1A;
    font-family: $font-nun-sans;
    font-size: 18px;
    text-align: center;
    margin-bottom: 46px;
  }
  &__add-reminder {
    color: #00cb79;
    font-family: $font-rob-mono;
    font-size: 18px;
    margin-bottom: 23px;
  }
  &__reminder-options {
    margin-bottom: 41px;
  }
  &__message {
    color: $color-1A;
    font-family: $font-nun-sans;
    font-size: 18px;
  }
}
