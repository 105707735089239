@import "../../../style/variables";

.form-textarea {
  grid-template-rows: [form-error-s] 25px [form-error-e input-s] 1fr [input-e];
  grid-template-columns: [s] 2fr [m] 10px [hover-icon-s] 20px [hover-icon-e e];
  display: grid;
  & > * {
    grid-row: input-s/input-e;
    grid-column: s/e;
  }
  &__error {
    grid-row: form-error-s/form-error-e;
    grid-column: m/e;
    justify-self: end;
  }
  &__title {
    grid-row: form-error-s/form-error-e;
    grid-column: s/m;
  }
  &__hover-icon {
    color: $color-33;
    font-size: 14px;

    justify-self: end;
    grid-column: hover-icon-s/hover-icon-e;
  }

  textarea {
    padding: 19px;
    font-family: $font-nun-sans;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: $color-80;
    height: 145px;
    box-sizing: border-box;
  }
}
