.social-media {
  display: grid;
  grid-template-rows: [s] 1fr [e];
  grid-template-columns: repeat(auto-fit, 1fr);
  justify-items: center;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0;

  &__item {
    grid-row: s/e;
    height: 46px;
    width: 46px;
    color: $color-80;
    font-size: 46px;
    text-decoration: none;
  }
}
