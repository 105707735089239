@import "../variables.scss";

.course-track-option {
  display: grid;
  justify-items: center;

  transition: all 1s ease;
  img {
    height: 60px;
    width: 60px;
    margin-top: 22px;
    margin-left: 40px;
    margin-right: 40px;
  }
  &__title {
    margin: 20px;
    margin-top: 24px;

    color: $color-66;
    font-family: $font-rob-mono;
    font-size: 14px;
    text-align: center;
  }
}

.course-track-option {
  &__back {
    opacity: 0;
    z-index: 10;

    background-color: white;
  }
  &:hover {
    .course-track-option__back {
      opacity: 1;
      height: auto;
    }
  }
  &__disabled {
    -webkit-filter: opacity(30%); /* Safari 6.0 - 9.0 */
    filter: opacity(30%);
  }
}

.cto-unselected {
  border: 2px solid transparent;
}

.cto-selected {
  border: 2px solid $color-CB;
  div {
    color: $color-CB;
  }
}
