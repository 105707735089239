.doc-field {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: [s] 1fr [m] 1.5fr [e];
  & > * {
    transition: all 0.1s ease;
    grid-row: 1/-1;
  }
  &__title {
    grid-column: s/m;
  }
  &__line {
    grid-column: m/e;
  }
}
