.terms-of-use {
  font-size: 13px;

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__address {
    font-weight: bold;
  }

  &__email {
    text-decoration: none;
  }

  &__back {
    margin-top: 20px;
    align-self: center;
    text-align: center;
  }

  ul {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    list-style: decimal;
  }

  li {
    padding: 0;
  }

  h1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
