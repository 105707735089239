@import "../mixins";

.loan-form {
  display: grid;
  grid-row-gap: 24px;
  margin-bottom: -160px;

  @include mobile {
    grid-row-gap: 0;
  }

  grid-template-rows: [message-s] auto [message-e options-s] 1fr [options-e buttons-s];
  grid-template-columns: [s] 1fr [e];

  align-items: start;

  & > * {
    grid-column: s/e;
  }
  &__message {
    @include p-text();
    grid-row: message-s/message-e;
  }
  &__options {
    grid-row: options-s/options-e;
  }

  &__buttons {
    grid-row: buttons-s;
    transform: translateY(60px);
    display: grid;
    grid-template-columns: [retake-s] 1fr [retake-e continue-s] 1fr [continue-e];
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    height: 200px;

    & > * {
      grid-row: 1/-1;
    }

    &__retake {
      button {
        background-color: $color-99;
      }
      grid-column: retake-s/retake-e;
    }

    &__submit {
      grid-column: continue-s/continue-e;
    }
  }
  &__submit-disabled {
    button {
      filter: opacity(0.7) grayscale(0.5);
    }
  }
}
