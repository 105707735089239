.payment-content {
  display: grid;
  grid-template-rows: [image-s] 100px [image-e title-s] 2fr [title-e format-s] 1fr [format-e price-s] 1fr [price-e];
  text-align: center;
  justify-items: center;
  align-items: center;

  &__checkout,
  &__continue {
    text-align: center;
  }

  &__track-title {
    grid-row: title-s/title-e;

    color: $color-CB;
    font-family: $font-rob-mono;
    font-size: 30px;
    @include mobile {
      font-size: 24px;
    }
  }

  &__track-logo {
    width: 96px;
    height: 96px;

    grid-row: image-s/image-e;
  }

  &__track-format {
    grid-row: format-s/format-e;

    color: $color-1A;
    font-family: $font-nun-sans;
    font-size: 18px;
  }

  &__track-price {
    grid-row: price-s/price-e;

    color: $color-4D;
    font-size: 18px;
    margin: 20px 0;
  }
}
