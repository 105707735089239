@import "../mixins";

.track-selection-card {
  display: grid;
  grid-template-rows: [tracks-s] 210px [tracks-e track-selected-s] 1fr [track-selected-e];
  grid-row-gap: 60px;

  @include mobile {
    grid-template-rows: [tracks-s] 250px [tracks-e track-selected-s] 1fr [track-selected-e];
    grid-row-gap: 30px;
  }

  &__tracks {
    grid-row: tracks-s/tracks-e;
  }
  &__track-selected {
    grid-row: track-selected-s/track-selected-e;
  }
  &__tracks,
  &__track-selected {
    margin: 0 30px;
    .tracks__line {
      margin: 0 -30px;
    }
  }
}
