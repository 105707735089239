.select-program {
  @include card;
  display: grid;
  grid-template-columns: [s] 1fr [m] 1fr [e];
  grid-template-rows: [img-s] 60px [img-e title-s] 60px [title-e] 100px [get-started-s] 0.7fr [get-started-e select-s] 0.7fr [select-e];

  justify-items: center;
  align-items: center;
  font-weight: normal;

  & > * {
    grid-column: s/e;
  }

  &__img {
    grid-row: img-s/img-e;
    filter: invert(100%);
    fill: black;

    object-fit: contain;

    align-self: center;
    margin: 10px 0px;

    height: 18px;
  }
  &__title {
    grid-row: title-s/title-e;
    color: $color-CB;
    font-family: $font-rob-mono;
    font-size: 34px;
    text-align: center;
  }
  &__get-started {
    grid-row: get-started-s/get-started-e;
    color: $color-33;
    font-size: 16px;
    font-weight: 500;
  }
  &__select {
    grid-row: select-s/select-e;
    @include green-button;
    display: grid;
    justify-items: center;
    align-items: center;
    margin-bottom: 40px;
  }
}
