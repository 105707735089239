@import "../mixins";

.commitment-slider {
  font-family: $font-nun-sans;
  font-weight: 300;
  z-index: 1;
  padding-top: 60px;
  padding-bottom: 25px;
  .rc-slider-rail {
    background-color: #e6e6e6;
    height: 6px;
    border-radius: 0px;
  }
  .rc-slider-track {
    background-color: $color-CB;
    height: 6px;
    border-radius: 0px;
  }
  .rc-slider-handle {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-left: -7px;
    margin-top: -8px;
    background: $color-CB;
    border-radius: 0;
    transform: rotate(45deg) !important;
    border: none;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);

    &:active {
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
    }
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-dot:nth-of-type(2) {
    display: unset;
    border-radius: 0;
    border: none;
    background: #e6e6e6;
    height: 30px;
    bottom: -14px;
    width: 3px;
    z-index: -1;
    margin: 0;
  }

  .rc-slider-tooltip {
    font-family: $font-nun-sans;
    font-weight: bold;

    .rc-slider-tooltip-inner {
      background: $color-33;
      border-radius: 0;
      height: 20px;
      padding: 4px 12px;
      white-space: nowrap;
    }
  }

  .warning {
    .rc-slider-track {
      background-color: #ba0000;
    }
    .rc-slider-handle {
      background: #ba0000;
    }
  }
}

.commitment-slider-values {
  font-family: $font-nun-sans;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;

  @include mobile {
    margin-left: -5px;
    margin-right: -5px;
  }

  .commitment-slider-value {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;

    @include mobile {
      margin-left: 5px;
      margin-right: 5px;
    }

    h1 {
      font-size: 26px;
      color: $color-CB;
      font-weight: 600;

      @include mobile {
        font-size: 15px;
      }
    }

    span {
      font-size: 12px;
    }
  }
}

.commitment-slider-warning {
  filter: drop-shadow(0 0 2px);
  margin-top: 20px;
  margin-right: 10px;

  .rc-tooltip-content {
    position: relative;
    right: 15px;
    top: 20px;
  }

  .rc-tooltip-inner {
    font-family: $font-nun-sans;
    font-size: 14px;
    line-height: 18px;
    width: 206px;
    border-radius: 0;
    background: white;
    color: black;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    border-left: #ba0000 5px solid;
    padding: 20px 20px 20px 35px;
  }

  &.rc-tooltip-placement-left .rc-tooltip-arrow {
    border-left-color: white;
    border-width: 15px 0 15px 15px;
    right: -15px;
    top: 35%;
  }
}
