@import "../../mixins";

.welcome-to-bottega {
  margin-bottom: 100px;
}

.welcome-to-bottega {
  &__enrollment-complete {
  }
  &__orentation-reminder {
  }
  &__be-ready {
    .titled-container {
      &__content {
        padding: 60px 40px !important;
        padding-bottom: 150px !important;
        display: grid;
        justify-items: center;

        @include mobile {
          padding: 10px !important;
        }

        .green-list__title {
          margin: 0px;
        }
        ul {
          margin-left: 20px;
          grid-template-columns: [s] 100% [e];

          @include mobile {
            margin-left: 10px;
          }
        }
      }
    }
  }
  &__social-media {
  }
}
