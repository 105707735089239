@import "../../mixins";

.sign-agreement {
  height: calc(100vh - 225px);
  min-height: 500px;
  display: grid;
  grid-template-rows: [doc-s] auto [doc-e continue-s] 100px [continue-e];
  grid-template-columns: 1fr;
  padding: 0;
  margin: 0;
  justify-items: center;
  grid-row-gap: 0;

  & > * {
    grid-column: 1/-1;
  }

  &__doc {
    grid-row: doc-s/doc-e;
  }
  &__continue {
    grid-row: continue-s/continue-e;
    align-self: center;
  }
}

.gray {
  color: $color-66;
}

.sign-agreement-doc {
  width: 700px;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);

  @include mobile {
    width: calc(100vw - 20px);
    overflow-x: scroll;
  }
}
