@import "./variables";

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin grid-center($row-gap: 0, $column-gap: 0, $gap: 0) {
  display: grid;
  justify-items: center;
  align-items: center;

  grid-row-gap: $row-gap;
  grid-column-gap: $column-gap;
  grid-gap: $gap;
}

@mixin green-heading(
  $font-size: 34px,
  $font-family: $font-rob-mono,
  $text-align: center,
  $color: $color-CB
) {
  font-family: $font-family;
  font-size: $font-size;
  text-align: $text-align;
  color: $color;
}

@mixin p-text(
  $font-size: 18px,
  $font-family: $font-nun-sans,
  $color: $color-1A,
  $text-align: center
) {
  font-family: $font-family;
  font-size: $font-size;
  text-align: $text-align;
  color: $color;
}

@mixin survey-title-text(
  $font-size: 13px,
  $font-family: $font-nun-sans,
  $font-weight: 300,
  $color: $color-1A
) {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin survey-option-text($font-family: $font-nun-sans) {
  color: $color-80;
  font-family: $font-family;
  font-size: 18px;
  font-weight: 300;
}

@mixin eighty-text() {
  color: $color-80;
  font-family: $font-nun-sans;
  font-size: 16px;
  text-align: center;
}

@mixin card() {
  width: 700px;
  background-color: $color-FF;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
  padding: 40px 21px;
  @include mobile {
    padding: 10px;
    width: auto;
  }
}

@mixin shadow-white() {
  background-color: $color-FF;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
}

@mixin transition($time: 0.3s, $scale: scale(1.05)) {
  transition: all $time ease;
  &:hover {
    transform: $scale;
  }
}

@mixin green-button() {
  height: 48px;
  width: 234px;
  background-color: #00cb79;
  color: $color-FF;
  font-family: $font-rob-mono;
  font-size: 20px;
  font-weight: 500;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  @include mobile {
    width: 160px;
    max-width: 43vw;
    font-size: 18px;
  }
}

@mixin white-button() {
  @include green-button;
  background-color: $color-FF;
  color: $color-1A;
  border: 1px $color-99 solid;
}

@mixin blue-link {
  cursor: pointer;
  text-decoration-line: underline;
  transition: all 0.3s ease;
  color: rgb(0, 157, 255);
  &:hover {
    transform: scale(1.025);
    color: $color-CB;
  }
}

@mixin subtle-gray-text {
  color: $color-99;
  font-family: $font-nun-sans;
}
