.student-information {
  display: grid;
  grid-template-rows: [title-s] 20px [title-e] 20px [content-s] 1fr [content-e];
  grid-template-columns: [title-s left-s] 1fr [left-e right-s] 1fr [right-e title-e];

  &__title {
    grid-row: title-s/title-e;
    grid-column: title-s/title-e;
  }
  &__left {
    grid-row: content-s/content-e;
    grid-column: left-s/left-e;
  }
  &__right {
    grid-row: content-s/content-e;
    grid-column: right-s/right-e;
    align-self: end;
  }
  span {
    margin-left: 10px;
    text-align: right;
    width: 100%;
  }
}

.gap-grid {
  display: grid;
  grid-row-gap: 3px;
}
