@import "./mixins";

.title-card {
  display: grid;
  grid-template-columns: [s] minmax(200px, 100%) [e];
  grid-template-rows: [header-s] auto [header-e content-s] 1fr [content-e] 10px;

  &__title,
  &__content {
    grid-column: s/e;
  }

  &__title {
    display: flex;
    z-index: 10;
    grid-row: header-s/header-e;
    align-items: center;
    padding-left: 52px;

    height: 55px;
    background-color: $color-1A;

    color: $color-FF;
    font-family: $font-rob-mono;
    font-size: 20px;
    line-height: 26px;

    font-weight: normal;
    font-weight: bold;

    @include mobile {
      min-height: 45px;
      padding: 0 15px;
      justify-items: center;
      text-align: center;
    }
  }

  &__content {
    overflow: auto;
    grid-row: content-s/content-e;

    background-color: $color-FF;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);

    padding: 40px 21px;

    @include mobile {
      padding: 10px 5px;
    }
  }
}
