@import "../mixins";

.loan-form-option {
  height: 640px;
  width: 315px;
  border: 1px solid $color-CC;

  display: grid;
  grid-template-rows: 60px [logo-s] 1fr [logo-e title-s] 1fr [title-e price-s] 1fr [price-e frequency-s] 1fr [frequency-e details-s] 1fr [details-e] 34px;
  grid-template-columns: 25px [s] 25px [logo-s] 5px [great-for-s] 1fr [great-for-e] 5px [logo-e] 25px [e] 25px;

  @include mobile {
    width: 100%;
    height: 500px;
    grid-template-rows: 10px [logo-s] 1fr [logo-e title-s] 1fr [title-e great-for-s] 1fr [great-for-e price-s] 1fr [price-e frequency-s] 1fr [frequency-e details-s] 1fr [details-e] 34px;
  }

  &__selected button {
    background-color: black !important;
  }

  & > * {
    grid-column: s/e;
  }
  &__logo {
    grid-row: logo-s/logo-e;
    grid-column: logo-s/logo-e;

    width: 100%;
    object-fit: contain;

    align-self: center;

    &-inverted {
      fill: #000;
      filter: invert(100%);
    }
  }
  &__title {
    grid-row: title-s/title-e;
    color: $color-4D;
    font-family: $font-rob-mono;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    align-self: end;
    margin-bottom: 9px;
  }
  &__price {
    grid-row: price-s/price-e;
    color: $color-CB;
    font-family: $font-nun-sans;
    font-size: 30px;
    font-weight: 600;
    text-align: center;

    align-self: end;
  }
  &__frequency {
    grid-row: frequency-s/frequency-e;
    border-bottom: 1px solid $color-CC;
    color: $color-80;
    font-family: $font-nun-sans;
    font-size: 12px;
    text-align: center;

    padding-top: 4px;
    padding-bottom: 35px;
  }
  &__details {
    margin-top: 48px;

    grid-row: details-s/details-e;
    color: $color-80;
    font-family: $font-nun-sans;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
  }
  &__select {
    margin: 34px 0;
    grid-row: select-s/select-e;

    @include mobile {
      margin: 10px 0 20px;
    }

    button {
      color: white;
      font-family: $font-rob-mono;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      width: 153px;
      height: 32px;

      &:focus {
        outline: none;
      }
    }
  }
}

.loan-form-option-container__selected {
  outline: 4px auto $color-CB !important;
  outline-offset: -2px;
}
