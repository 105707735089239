@import "../../style/variables";
@import "../../style/mixins";

.financial-guidance-form {
  display: grid;
  grid-template-columns: [s] 1fr [e];
  grid-template-rows: [title-s] auto [title-e content-s] 1fr [content-e buttons-s] 200px [buttons-e];
  align-items: start;
  width: 700px;

  @include mobile {
    width: 100%;
  }

  & > * {
    grid-column: s/e;
  }

  &__title {
    grid-row: title-s/title-e !important;
    grid-column: s/e;

    color: $color-1A;
    font-family: $font-nun-sans;

    z-index: 10;

    display: grid;
    align-items: center;
    padding-left: 52px;

    height: 55px;
    background-color: $color-1A;

    color: $color-FF;
    font-family: $font-rob-mono;
    font-size: 20px;
    line-height: 26px;

    font-weight: normal;

    @include mobile {
      min-height: 45px;
      padding-left: 0;
      justify-items: center;
      text-align: center;
    }
  }

  &__content {
    @include card;
    display: grid;
    grid-row-gap: 24px;
    padding: 0;
    padding-bottom: 40px;

    grid-template-columns: 20px [s] 0.5fr [m] 0.5fr [e] 20px;
    grid-template-rows: [message-s] 120px [message-e] repeat(auto-fit, 66px);

    @include mobile {
      grid-template-columns: [s] 0.5fr [m] 0.5fr [e];
    }

    align-items: start;

    &__message {
      grid-row: message-s/message-e;
      grid-column: s/e;
      padding: 46px 66px;

      @include mobile {
        padding: 0;
      }

      @include p-text();
    }

    & > * {
      grid-column: s/e;
    }
  }

  &__employment {
    grid-column: s/m;
    width: 295px;

    @include mobile {
      grid-column: s/e;
      width: 100%;
    }
  }

  &__income {
    grid-column: m/e;
    width: 295px;
    justify-self: right;

    @include mobile {
      grid-column: s/e;
      width: 100%;
      justify-self: auto;
    }
  }

  &__not_sure {
    grid-column: s/e;
    justify-self: left;

    @include mobile {
      grid-column: s/e;
      width: 100%;
      justify-self: auto;
    }
  }

  &__buttons {
    height: 100%;
    grid-row: buttons-s/buttons-e;
    display: grid;
    grid-template-columns: [back-s] 1fr [back-e continue-s] 1fr [continue-e];
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    & > * {
      grid-row: 1/-1;
    }

    &__back {
      button {
        background-color: $color-99;
      }
      grid-column: back-s/back-e;
    }
    &__continue {
      grid-column: continue-s/continue-e;
    }
  }
}
