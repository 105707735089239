.doc-page {
  display: grid;
  grid-template-rows: [content-s] 1fr [content-e page-number-s] 10px [page-number-e];
  grid-template-columns: 1fr;

  padding: 50px;
  border-bottom: 0.2px solid #333;

  &__content {
    grid-row: content-s/content-e;
    grid-column: 1/-1;
  }

  &__page-number {
    grid-row: page-number-s/page-number-e;
    grid-column: 1/-1;
    justify-self: end;
    align-self: end;

    font-size: 18px;
    font-weight: bold;
    font-family: $font-nun-sans;
  }
}

.doc-page__content {
  &__centered {
    & > * {
      text-align: center;
    }
  }
  & > * {
    color: black;
    font-size: 12px;
    font-weight: normal;
    font-family: $font-nun-sans;
  }
  &__title {
    font-size: 28px;
    font-weight: bold;
  }
  &__large-text {
    font-size: 16px;
    font-weight: 500;

    margin: 2px 0;
  }

  &__paragraph {
    font-size: 14px;
    font-weight: normal;
    margin: 20px 0;
  }

  &__small-number-list-item {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 7px 1fr;
    div {
      grid-column: 2/3;
      grid-row: 1/-1;
    }
    span {
      font-size: 8px;
      grid-column: 1/2;
      grid-row: 1/-1;
    }
  }
}
