.faq {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 14px;
  }
  &__qas {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 10px;
    &__qa {
      display: flex;
      flex-direction: column;
      &_q {
        font-size: 14px;
      }
      &_a {
        font-size: 14px;
      }
    }
  }
}
