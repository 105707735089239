@import "../mixins";

.tracks {
  $line-height: 10px;
  display: grid;
  grid-template-rows: [tracks-s] 1fr [tracks-e line-s] $line-height [line-e];
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  & > * {
    grid-column: 1/-1;
  }
  &__tracks {
    grid-row: tracks-s/tracks-e;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    & > * {
      grid-row: 1/-1;
    }
    @include mobile {
      grid-template-rows: 0.9fr;
    }
  }
  &__line {
    grid-row: line-s/line-e;
    $line-height: 10px;
    border-bottom: 2px solid black;
    position: relative;

    &:after,
    &:before {
      top: 0%;
      left: 10%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      transition: all 0.3s ease;
    }
    &:after {
      border-bottom-color: rgb(255, 255, 255);
      border-width: 10px;
      transform: translateY(-8px) scaleX(2) translateX(-15px);
    }
    &:before {
      border-bottom-color: #000000;
      border-width: $line-height;
      transform: translateY(-$line-height) scaleX(2) translateX(-15px);
    }
  }

  &__pos-1 {
    &:after,
    &:before {
      left: 12.5%;
    }
    &:after {
      transform: translateY(-8px) scaleX(2) translateX(5px);
    }
    &:before {
      transform: translateY(-$line-height) scaleX(2) translateX(5px);
    }
  }
  &__pos-2 {
    &:after {
      transform: translateY(-8px) scaleX(2) translateX(0px);
    }
    &:before {
      transform: translateY(-$line-height) scaleX(2) translateX(0px);
    }
    &:after,
    &:before {
      left: 12.5% * 3;
    }
  }
  &__pos-3 {
    &:after {
      transform: translateY(-8px) scaleX(2) translateX(-10px);
    }
    &:before {
      transform: translateY(-$line-height) scaleX(2) translateX(-10px);
    }
    &:after,
    &:before {
      left: 12.5% * 5;
    }
  }
  &__pos-4 {
    &:after {
      transform: translateY(-8px) scaleX(2) translateX(-15px);
    }
    &:before {
      transform: translateY(-$line-height) scaleX(2) translateX(-15px);
    }
    &:after,
    &:before {
      left: 12.5% * 7;
    }
  }
}
