// TODO: cleanup duplicate & unused styles

@import "variables.scss";

@import "titled-container.scss";

// MIXINS
@import "mixins.scss";

// REDUX FORM FIELDS
@import "form-fields.scss";

// LAYOUT
@import "layout/layout.scss";
@import "layout/branded-header.scss";
@import "layout/progress-bar.scss";

// PAGE LAYOUT
@import "pageLayout/page-layout.scss";
@import "pageLayout/account-layout.scss";

// APPLICATION
@import "application/account-created.scss";
@import "application/course-selection.scss";
@import "application/select-course-track-option.scss";
@import "application/select-program.scss";

@import "application/applicantInformation/applicant-information.scss";
@import "application/applicantInformation/applicant-information-card.scss";
@import "application/applicantInformation/applicant-information-form-fields.scss";

@import "green-titled-info.scss";
@import "green-titled-list.scss";

@import "doc.scss";

// DOCUMENT COLLECTION
@import "application/document-collection.scss";

// COMMITMENT ASSESSMENT
@import "commitmentAssessment/commitment-assessment-form.scss";
@import "commitmentAssessment/commitment-slider.scss";

// ACCOUNT SETUP
@import "accountSetup/student-orientation-form.scss";

// FINANICAL GUIDANCE
@import "financialGuidance/financial-guidance.scss";
@import "financialGuidance/financial-guidance-form.scss";
@import "financialGuidance/loan-form.scss";
@import "financialGuidance/loan-form-options-container.scss";
@import "financialGuidance/loan-form-option.scss";

// PAYMENT
@import "application/payment.scss";

// REVIEW
@import "review/review.scss";
@import "review/scheduled-student-orientation.scss";
@import "review/social-media.scss";

@import "numbered-list.scss";

@import "student-information.scss";
@import "doc-field.scss";

@import "partTwo/scheduleOrientation/schedule-orientation.scss";
@import "partTwo/signAgreement/sign-agreement.scss";
@import "partTwo/welcomeToBottega/welcome-to-bottega.scss";
@import "partTwo/welcomeToBottega/cohort-details.scss";
@import "partTwo/welcomeToBottega/orientation-reminder.scss";
@import "partTwo/signAgreement/page-doc.scss";
@import "partTwo/signAgreement/program-selection.scss";
@import "partTwo/signAgreement/questionsAnswers.scss";
@import "partTwo/signAgreement/sign-button.scss";
@import "partTwo/enrollmentAgreement/enrollment-agreement.scss";

// HELPERS
@import "helpers/popup.scss";

@import "title-card.scss";

// TRACKS
@import "trackSelection/track-selection-card.scss";
@import "trackSelection/track-selection-form.scss";
@import "trackSelection/track.scss";
@import "trackSelection/tracks.scss";
@import "trackSelection/track-selected.scss";
@import "trackSelection/track-selected-options.scss";

@import "form-with-buttons.scss";

// APPLICANT LOGIN
@import "applicantAuth/applicant-create-account.scss";
@import "applicantAuth/terms-of-use.scss";
@import "applicantAuth/login-signup-forms.scss";

@import "info-card.scss";

html {
  font-size: 62.5%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  color: $color-80;
  font-family: $font-rob-mono;
  font-size: 10px;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.grid {
  &__snatch {
    background-color: skyblue;
    border-radius: 12px;
    height: 100px;
    width: 100%;
    font-size: 40px;
    color: white;
  }
  &__users {
    display: grid;
    grid-gap: 24px;
  }
}

.user {
  background-color: rgb(136, 56, 255);
  display: grid;
  justify-items: start;
  padding: 10px 50px;
  border-radius: 12px;
  color: white;
  &__name {
  }
  &__age {
  }
}

.horizontal-field-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.underlined {
  text-decoration: none;
  border-bottom: 1px solid rgb(26, 26, 26);
}

.italics {
  font-style: italic;
}

.signed {
  line-height: 24px;
  font-size: 16px;
  font-family: "Homemade Apple", cursive;
}

.hide {
  opacity: 0;
}

.highlight {
  color: $color-CB;
  font-weight: bold;
  outline-color: rgba(0, 203, 121, 0.4);
}

.devcamp-link {
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  &:hover {
    font-size: 18px;
    color: $color-CB;
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 20px 5px rgba(0, 203, 122, 0.5);

  display: grid;
  align-items: center;
  justify-items: center;
}

.help-icon {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 12;

  width: 50px;
  height: 50px;

  background-color: #fff;
  box-shadow: 0 0 20px 5px rgba(50, 50, 50, 0.2);
  border-radius: 50%;

  &__content {
    font-family: $font-nun-sans;
    font-size: 14px;
    padding: 10px;
  }

  &__trigger {
    padding: 10px;
    text-align: center;

    a {
      color: $color-CB;
      font-size: 24px;
    }
  }
}

.header {
  &__black {
    color: black;
    font-family: $font-nun-sans;
    font-size: 24px;
    font-weight: 500;
  }
}

.error-reporter {
  &__error {
    padding-top: 10px;
    font-size: 20px;
    color: $color-CB;
    text-align: center;
  }

  &__info {
    text-align: center;
    font-size: 18px;
    color: #000;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
