@import "../mixins";

.track {
  display: grid;
  justify-items: center;

  transition: all 0.2s ease;
  img {
    height: 60px;
    width: 60px;
    margin-top: 22px;
    margin-left: 40px;
    margin-right: 40px;

    @include mobile {
      height: 40px;
      width: 40px;
      margin: 0;
      align-self: center;
    }
  }
  &__title {
    margin: 20px;
    margin-top: 24px;

    color: $color-66;
    font-family: $font-rob-mono;
    font-size: 14px;
    text-align: center;

    @include mobile {
      transform: rotate(180deg);
      transform-origin: center;
      align-self: center;
      text-align: left;
      margin: 0 0 20px;
      height: 100px;
      writing-mode: tb;
    }
  }
}

.track-option-unselected {
  border: 2px solid transparent;
}

.track-option-selected {
  border: 2px solid $color-CB;
  div {
    color: $color-CB;
  }
}
