@import "../mixins";

.option-box {
  display: grid;
  grid-template-rows: [header-s] 10px [header-e options-s] 1fr [options-e];
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  & > * {
    grid-column: 1/-1;
  }
  &__header {
    grid-row: header-s/header-e;

    display: grid;
    grid-template-columns: [title-s] 1fr [title-e hover-icon-s] 20px [hover-icon-e];
    grid-template-rows: 1fr;
    align-items: center;

    & > * {
      grid-row: 1/-1;
    }
    &__title {
      color: $color-33;
      font-size: 16px;

      grid-column: title-s/title-e;

      @include mobile {
        font-size: 14px;
      }
    }
    &__hover-icon {
      color: $color-80;
      font-size: 18px;

      justify-self: end;
      grid-column: hover-icon-s/hover-icon-e;
    }
  }
  &__options {
    grid-row: options-s/options-e;
    .option-box-option {
      margin: 10px 0;
      padding: 8px 16px;
      border: 1px solid $color-99;
      color: $color-33;
      font-size: 14px;
      transition: all 0.3s ease;

      &:hover {
        border-color: $color-4D;
        border-width: 2px;
        padding: 7px 15px;
      }
      &__selected {
        color: $color-CB;
        border-color: $color-CB !important;
        border-width: 2px;
        padding: 7px 15px;
      }
      &__error {
        border-color: #ff0000 !important;
      }
    }
  }
}

.track-selected-options {
  font-family: $font-nun-sans;

  display: grid;
  grid-template-rows: [select-format-s] 1fr [select-format-e select-start-date-s] auto [select-start-date-e];
  grid-template-columns: 1fr;

  & > * {
    grid-column: 1/-1;
  }
  &__select-format {
    grid-row: select-format-s/select-format-e;
  }
  &__select-start-date {
    grid-row: select-start-date-s/select-start-date-e;
    margin-top: 30px;
  }
}

.track-format-popup {
  color: #808080;
  font-family: $font-rob-mono;

  &__header {
    color: #00cb79;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &__program {
    color: #000;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
