@import "./mixins";

button {
  -webkit-appearance: none;
  border: none;
}

.form-field-error {
  color: red;
  font-weight: 200;
  font-size: 14px;
}
.form-field-error ~ input,
.form-field-error ~ select {
  outline: 3px auto rgba(255, 0, 0, 0.27);
  outline-offset: -2px;
}

.disabled {
  opacity: 0.5;
}

.form-input,
.form-drop,
.form-file,
.form-textarea {
  grid-template-rows: [header-s] minmax(25px, auto) [header-e input-s] 1fr [input-e];
  grid-template-columns: [s] 2fr [m] 1fr [hover-icon-s] auto [hover-icon-e e];
  display: grid;

  & > * {
    grid-row: input-s/input-e;
    grid-column: s/e;
  }
  &__error {
    grid-row: header-s/header-e;
    grid-column: m/hover-icon-s;
    justify-self: end;
    align-self: center;
    margin-bottom: 8px;
    background-color: $color-FF;
    padding-left: 5px;
    text-align: right;
  }
  &__title {
    grid-row: header-s/header-e;
    grid-column: s/e;
  }
  &__subtitle {
    grid-row: header-s/header-e;
    grid-column: m/e;
    text-align: right;

    a {
      @include blue-link;
    }

    div {
      display: inline-block;
    }
  }
  &__hover-icon {
    color: $color-80;
    font-size: 18px;
    padding-left: 5px;
    justify-self: end;
    align-self: center;
    grid-row: header-s/header-e;
    grid-column: hover-icon-s/hover-icon-e;
    margin-bottom: 8px;
  }
  &__checkbox {
    label {
      display: flex;
      font-size: 18px;
      font-weight: 300;
      font-family: $font-nun-sans;

      span {
        align-self: center;

        &:before {
          content: " ";
          height: 15px;
          width: 15px;
          position: relative;
          display: inline-block;
          top: 2px;
          border-left: 15px solid #eee;
        }
      }

      :checked + span:before {
        border-left: 15px solid $color-CB;
      }
    }
  }
}

.form-input-text-error {
  grid-template-columns: [s] 1fr [m] 1fr [e];
  .form-input__error {
    font-size: 10px !important;
  }
}

.form-input,
.form-drop,
.form-file,
.form-textarea {
  width: 100%;

  &__title,
  &__subtitle {
    color: $color-1A;
    font-family: $font-nun-sans;

    color: $color-1A;
    font-family: $font-nun-sans;
    font-size: 13px;
    font-weight: 300;

    margin-bottom: 8px;
  }
  input,
  select {
    height: 35px;
  }
  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #d2d2d2bb;
    box-shadow: inset 0 0 2px 1px rgba(103, 103, 103, 0.06);
    font-size: 18px;

    &::placeholder {
      color: $color-80;
      font-family: $font-nun-sans;
      font-weight: 300;
    }
    &:focus {
      border-color: transparent !important;
      box-shadow: none;
      outline: 1px solid rgba(0, 203, 121, 0.4);
    }
    text-indent: 10px;

    @include mobile {
      font-size: 14px;
    }
  }

  input[type="checkbox"] {
    width: auto;
    display: none;
  }
}

.form-file input {
  text-indent: 0;
}
