@import "../../variables";

.sign-button {
  &__sign {
    transition: all 0.3s ease;
  }
}

.sign-button-unsigned {
  .sign-button {
    &__sign {
      color: $color-CB;
      font-weight: bold;
      outline-color: rgba(0, 203, 121, 0.4);
    }
  }
}

.sign-button-signed {
  .sign-button {
    &__sign {
      font-style: italic;
      font-size: 12px;
      font-family: "Homemade Apple", cursive;
    }
  }
}
