.program-selection {
  display: grid;
  grid-template-rows: [expected-start-date-s] 14px [expected-start-date-e courses-s] auto [courses-e expected-end-date-s] auto [expected-end-date-s];
  grid-template-columns: 1fr;
  grid-row-gap: 20px;

  &__title-value {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: [title-s] 1fr [title-e value-s] 1fr [value-e];

    &__title {
      grid-row: 1/-1;
      grid-column: title-s/title-e;
    }
    &__value {
      grid-row: 1/-1;
      grid-column: value-s/value-e;
      margin-left: 10px;
      border-bottom: 1px solid black;
    }
  }

  &__courses {
    margin: 20px 0px;

    &__stars {
      font-family: $font-rob-mono;
      color: $color-66;
    }
    &__bold {
      font-size: 14px;
      font-weight: bold;
    }
    &__description {
    }
  }
  &__expected-end-date {
  }
}

.statement-of-tuition {
  margin-top: 80px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  &__avenue {
    margin-bottom: 10px;
  }

  & > * {
    grid-column: 1/-1;
  }

  &__line {
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
  }
}

.bold {
  font-weight: bold;
}

.doc-acceptance {
  margin-top: 80px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}
