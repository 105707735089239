@import "../mixins";

.signup-form {
  width: 100%;
  grid-template-rows: [info-s] 120px [info-e] 10px [name-s] 80px [name-e email-s] 80px [email-e password-s] 80px [password-e link-s] 10px [link-e];
  &__name {
    grid-row: name-s/name-e;
  }
}
.login-form {
  width: 100%;
  grid-template-rows: [info-s] 120px [info-e] 10px [email-s] 80px [email-e password-s] 80px [password-e link-s] 10px [link-e];
}

.login-form,
.signup-form {
  display: grid;

  grid-template-columns: 1fr;
  justify-items: center;

  grid-row-gap: 10px;

  & > * {
    grid-column: 1/-1;
  }
  &__info {
    grid-row: info-s/info-e;
    width: 150%;
  }

  &__email {
    grid-row: email-s/email-e;
  }
  &__password {
    grid-row: password-s/password-e;
  }
  &__link {
    @include blue-link;
    grid-row: link-s/link-e;
  }
}
