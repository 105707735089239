@import "../mixins";

.branded-header {
  background-color: $color-1A;
  box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.2);

  display: grid;
  grid-template-rows: [s] 1fr [e];
  grid-template-columns: 140px [s] 1fr [e];

  @include mobile {
    grid-template-columns: [s] 1fr [e];
    justify-items: center;
  }

  align-items: center;

  &__logo {
    grid-row: s/e;
    grid-column: s/e;
    height: 16px;
  }
}
