@import "../mixins";

.applicant-resume-application-login,
.applicant-create-account {
  display: grid;
  grid-template-rows: [info-s] 130px [info-e] 15px [first-name-s] 80px [first-name-e last-name-s] 80px [last-name-e email-s] 80px [email-e phone-number-s] 80px [phone-number-e password-s] 80px [password-e] 10px [privacy-text-s] 55px [privacy-text-e login-s] 80px [login-e info-text-s] 55px [info-text-e];
  grid-template-columns: 1fr [s] minmax(100%, 350px) [e] 1fr;
  justify-items: center;
  align-items: center;
  grid-row-gap: 10px;

  @include mobile {
    grid-template-rows: [info-s] 130px [info-e] 15px [first-name-s] 80px [first-name-e last-name-s] 80px [last-name-e email-s] 80px [email-e phone-number-s] 80px [phone-number-e password-s] 80px [password-e] 10px [privacy-text-s] 55px [privacy-text-e login-s] 40px [login-e info-text-s] 55px [info-text-e];
  }

  & > * {
    grid-column: s/e;
  }
  &__info-card {
    grid-column: 1/-1;
    grid-row: info-s/info-e;
  }
  &__first-name {
    grid-row: first-name-s/first-name-e;
  }
  &__last-name {
    grid-row: last-name-s/last-name-e;
  }
  &__email {
    grid-row: email-s/email-e;
  }
  &__phone-number {
    grid-row: phone-number-s/phone-number-e;
  }
  &__password {
    grid-row: password-s/password-e;
  }
  &__login {
    grid-row: login-s/login-e;
  }
  &__info-text {
    @include subtle-gray-text;
    font-size: 12px;
    grid-row: info-text-s/info-text-e;
    text-align: center;
  }
  &__privacy-text {
    @include subtle-gray-text;
    font-size: 12px;
    grid-row: privacy-text-s/privacy-text-e;
    text-align: center;
  }
  &__link {
    @include blue-link;
  }
  &__inline-link {
    @include blue-link;
    text-decoration: none;
  }
}

.applicant-resume-application-login {
  grid-template-rows: 20px [info-s] 40px [info-e email-s] 80px [email-e password-s] 80px [password-e] 10px [login-s] 80px [login-e] 15px [info-text-s] 30px [info-text-e create-account-s] 80px [create-account-e];

  @include mobile {
    grid-template-rows: 20px [info-s] 20px [info-e email-s] 80px [email-e password-s] 80px [password-e] 10px [login-s] 40px [login-e] 15px [info-text-s] 30px [info-text-e create-account-s] 60px [create-account-e];
  }

  &__button {
    @include white-button;
    font-weight: initial;
  }

  &__create-account-button {
    grid-row: create-account-s/create-account-e;
  }

  &__info-text {
    grid-row: info-text-s/info-text-e;
    text-align: center;
    color: $color-1A;
    font-family: $font-rob-mono;
  }

  &__button,
  &__info-text {
    font-size: 16px;

    @include mobile {
      font-size: 14px;
    }
  }
}
