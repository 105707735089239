@import "../mixins";

.track-selection-form {
  display: grid;
  grid-template-rows: 1fr 200px;
  width: 750px;

  @include mobile {
    width: 100%;
  }

  &__card {
    grid-row: 1/2;
  }

  &__buttons {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: [cancel-s] 1fr [cancel-e save-close-s] 1fr [save-close-e];
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    & > * {
      grid-row: 1/-1;
    }
    .track-selection-form {
      &__cancel {
        grid-column: cancel-s/cancel-e;

        &.gray {
          button {
            background-color: $color-99;
          }
        }
      }
      &__save-and-close {
        grid-column: save-close-s/save-close-e;
      }
    }
  }
}
