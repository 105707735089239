@import "../mixins";

.account-layout {
  position: relative;
  width: 100vw;

  display: grid;
  grid-template-columns: [s] 140px [content-s] 1fr [content-e] 140px [e];
  grid-template-rows: [header-s] 50px [header-e] 100px [content-s] 1fr [content-e];
  justify-items: center;

  @include mobile {
    grid-template-columns: [s] 10px [content-s] 1fr [content-e] 10px [e];
    grid-template-rows: [header-s] 50px [header-e] 30px [content-s] 1fr [content-e];
  }

  &__header {
    width: 100vw;
    grid-column: s/e;
    grid-row: header-s/header-e;
  }

  &__content {
    grid-column: content-s/content-e;
    grid-row: content-s/content-e;
  }
}
