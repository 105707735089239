@import "../../mixins";

.applicant-information-card {
  display: grid;
  grid-template-columns: [s] 0.5fr [se] 0.5fr [sm] 0.5fr [m] 0.5fr [me] 1fr [e];
  justify-items: center;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  margin-bottom: 40px;
  width: 100%;

  @include mobile {
    margin-bottom: 0;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }
}
