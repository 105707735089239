@import "../../../style/mixins";

.form-drop {
  i.drop-down-icon {
    grid-row: input-s/input-e;
    grid-column: s/e;
    justify-self: end;
    align-self: center;

    margin-right: 10px;
    width: 2px;
    height: 2px;
    transform: rotate(45deg);

    border: solid $color-4D;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;

    @include mobile {
      margin-right: 7px;
    }
  }

  select {
    -webkit-appearance: none;

    font-family: $font-rob-mono;
    border-radius: 0px !important;

    width: 100%;
    border-radius: 0px !important;
    text-indent: 10px;

    option {
      color: $color-80;
      font-family: $font-nun-sans;
      font-size: 18px;
      font-weight: 300;
      border-radius: 0px !important;

      @include mobile {
        font-size: 14px;
      }
    }
    &:focus {
      border-radius: 0px !important;
      outline: 1px solid rgba(0, 203, 121, 0.4);
    }

    @include mobile {
      text-indent: 3px;
    }
  }

  &__option {
    font-family: $font-rob-mono;
  }
}

.form-drop {
  select {
    font-family: $font-nun-sans;
    font-size: 18px;
    font-weight: 300;
    color: $color-80;
    background: $color-FF;

    @include mobile {
      font-size: 14px;
    }
  }
}
